import {
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useState
} from 'react'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import VideoMini from '@organisms/video-mini'
export interface IAccordionChangeEvent extends Event {
  isActive: boolean
  item: HTMLElement | null
}
interface Item {
  titleModule: string
  idVideo: string
  videoPlaceholder?: any
  titleMovie: string
  descriptionVideo: string
}

interface IAccordionItemProps extends PropsWithChildren {
  items: Item[]
  active?: boolean
  onChange?: (event?: IAccordionChangeEvent) => void
}

export default function AccordionItemVideo({
  active,
  items,
  onChange = () => {},
  ...extraProps
}: IAccordionItemProps) {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(active ?? false)
  }, [active])

  const handleClick: MouseEventHandler = ({ target }) => {
    setIsActive((currentValue) => !currentValue)
    const targetElement = target as HTMLElement
    const rootElement = targetElement.closest<HTMLElement>(`.${styles.root}`)
    onChange({
      ...new Event('change'),
      isActive: !isActive,
      item: rootElement
    })
  }

  const buildItemClassName = () => {
    let itemClassName = styles.root
    itemClassName += isActive ? ` ${styles['root--active']}` : ''
    return itemClassName
  }

  return (
    <div role={'listitem'} className={buildItemClassName()} {...extraProps}>
      <hgroup className={styles.root__heading} onClick={handleClick}>
        <FeatherIcon icon="play-circle" />
        <h3 className={styles.root__title}>
          {items.length > 0 ? items[0]?.titleModule : 'Próximamente'}
        </h3>
        <button
          className={styles['root__toggle-button']}
          type={'button'}
          title={'Click para colapsar el elemento'}
          data-action={'toggle'}>
          <FeatherIcon icon={isActive ? 'chevron-up' : 'chevron-down'} />
        </button>
      </hgroup>
      <div className={styles.root__content}>
        {items.map((item, index) => (
          <div key={index} className={styles.root__content__item}>
            {item.idVideo !== 'PROX' ? (
              <>
                <VideoMini
                  idVideo={item.idVideo}
                  videoPlaceholder={item.videoPlaceholder ?? undefined}
                />
                <span className={styles.root__content__item_title}>
                  {item.titleMovie}
                </span>
                <p className={styles.root__content__item_description}>
                  {item.descriptionVideo}
                </p>
              </>
            ) : (
              <h4 className={styles.root__content__item__emptry}>
                Próximamente ...
              </h4>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
