import { useEffect, useState } from 'react'
import Image from 'next/image'
import styles from './index.module.scss'
import FeatherIcon from 'feather-icons-react'

interface VideoMiniProps {
  idVideo: string;
  videoPlaceholder: {
    url: string;
  };
}

export default function VideoMini(props: VideoMiniProps) {

  const [isPlaying, setIsPlaying] = useState(false)
  const [domAvailable, setDomAvailable] = useState(false)

  useEffect(() => {
    setDomAvailable(true)
  }, [])

  return (
    <section className={styles.root} role={'contentinfo'} id={'video-section'}>
      {domAvailable && (
        <div className={styles['root__video-wrapper']}>
          {isPlaying ? (
            <iframe
              className={styles['root__embed-video']}
              width="560"
              height="315"
              src={`https://www.youtube-nocookie.com/embed/${props.idVideo}?autoplay=1&modestbranding=1&rel=0`}
              title="¿Cómo funciona Kliiker?"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          ) : (
            <div
              role={'group'}
              className={styles['root__video-placeholder']}
              onClick={() => setIsPlaying(true)}>
              <Image
                className={styles['root__placeholder-image']}
                src={props.videoPlaceholder.url}
                alt={'Imagen del video informativo sobre Kliiker'}
                width={739.62}
                height={200}
                priority
              />
              <FeatherIcon className={styles['root__placeholder-icon']} icon="play" />
            </div>
          )}
        </div>
      )}
    </section>
  )
}
