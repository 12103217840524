import { IOnboardingStepProps } from '@molecules/index'
import bienvenida from '@/assets/img/onboarding-section/bienvenida.png'
// import paso3 from '@/assets/img/onboarding-section/paso3.png'
// import paso1 from '@/assets/img/onboarding-section/paso3.png'
// import paso2 from '@/assets/img/onboarding-section/paso3.png'
// import finalizar from '@/assets/img/onboarding-section/finalizar.png'
import finalizar from '@/assets/img/onboarding-section/Academia_onboarding.png'

export const stepsList: IOnboardingStepProps[] = [
  {
    image: bienvenida,
    Title: (
      <>
        Felicitaciones, <strong>es hora de hacer tu primera venta.</strong>
      </>
    ),
    description:
      'A continuación te presentamos a Fisapay, nuestro aliado que pagará tus ganancias cada 8 días.'
  },
  // {
  //   image: paso1,
  //   Title: (
  //     <>
  //       1. Crea tu <strong>link único Kliiker</strong>
  //     </>
  //   ),
  //   description:
  //     'Para empezar a ganar tus comisiones crea tu link con el cual tus conocidos y compradores te identificarán.'
  // },
  // {
  //   image: paso2,
  //   Title: (
  //     <>
  //       2. Queremos conocerte <strong>un poco más</strong>
  //     </>
  //   ),
  //   description:
  //     'Diligencia los datos adicionales personales para conocernos un poco más.'
  // },
  // {
  //   image: paso3,
  //   Title: (
  //     <>
  //       Tus comisiones <strong>con Fisapay</strong>
  //     </>
  //   ),
  //   description:
  //     'Fisapay es el aliado de Kliiker que pagará tus comisiones y beneficios adicionales. Te invitamos a aceptar el contrato de corretaje mercantil, los términos y condiciones para finalizar tu registro.'

  // },
  // {
  //   image: finalizar,
  //   Title: (
  //     <>
  //       <strong>Este es el link de tu tienda.</strong> Compra o vende 250.000 y
  //       gánate una camiseta.
  //     </>
  //   )
  // }
  {
    image: finalizar,
    Title: (
      <>
        <strong>Estas un paso más cerca</strong>
        <br />
        ¡Con Academia Kliiker podrás aprender cómo vender y generar ingresos
        extra!
      </>
    )
  }
]
