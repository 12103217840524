import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import Image from 'next/image'
import styles from './index.module.scss'
import quotation from '@/assets/img/register-page/quotation.png'
import userImage1 from '@/assets/img/register-page/user-1.png'
import userImage2 from '@/assets/img/register-page/user-2.png'
import userImage3 from '@/assets/img/register-page/user-3.png'

export default function PreregisterOurCommunity() {
  return (
    <Swiper
      className={styles.root}
      role={'group'}
      modules={[Autoplay, Pagination]}
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      }}
      loop={true}
      pagination={{
        clickable: true
      }}
      slidesPerView={'auto'}
      breakpoints={{ '1024': { slidesPerView: 3 } }}
      onResize={(swiper) => swiper.update()}
      centerInsufficientSlides>
      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__our_community_slide}>
          <Image
            className={styles.root__quotation}
            src={quotation}
            alt={'quotation'}
            priority
          />
          <p className={styles.root__our_community_comment}>
            Buscando generar ingresos adicionales, coincidencialmente me
            encontré con Kliiker. Me pareció un formato diferente desde un
            principio y hoy día me he beneficiado grandemente de Kliiker tanto
            en compras personales, como en compras para negocio.{' '}
          </p>
          <div className={styles.root__our_community_user_content}>
            <div className={styles.root__our_community_user_image}>
              <Image
                className={styles.root__user_image}
                src={userImage3}
                alt={'Camilo'}
                priority
              />
            </div>
            <div className={styles.root__our_community_user_info}>
              <strong className={styles.root__our_community_user_name}>
                Camilo
              </strong>
              <p className={styles.root__our_community_user_city}>
                <i>Soledad, Atlántico</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__our_community_slide}>
          <Image
            className={styles.root__quotation}
            src={quotation}
            alt={'quotation'}
            priority
          />
          <p className={styles.root__our_community_comment}>
            Ser Kliiker ha sido una gran experiencia y excelente oportunidad
            para hacer ingresos extra. Me ha permitido vivir gratos momentos
            asistiendo a varias Experiencias Kliiker donde recibí múltiples
            regalos y aprendí mucho de tendencias, estilos y tipos de prendas.
          </p>
          <div className={styles.root__our_community_user_content}>
            <div className={styles.root__our_community_user_image}>
              <Image
                className={styles.root__user_image}
                src={userImage1}
                alt={'Deicy'}
                priority
              />
            </div>
            <div className={styles.root__our_community_user_info}>
              <strong className={styles.root__our_community_user_name}>
                Deicy
              </strong>
              <p className={styles.root__our_community_user_city}>
                <i>Medellín, Antioquia</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__our_community_slide}>
          <Image
            className={styles.root__quotation}
            src={quotation}
            alt={'quotation'}
            priority
          />
          <p className={styles.root__our_community_comment}>
            Mi experiencia en Kliiker ha sido la mejor, soy una apasionada por
            las ventas y por el servicio al cliente. Generar ingresos acá es muy
            fácil, puedes administrar tu tiempo, no necesitas invertir, no
            tienes que preocuparte por cobrar, tampoco desplazarte para entregar
            los pedidos.
          </p>
          <div className={styles.root__our_community_user_content}>
            <div className={styles.root__our_community_user_image}>
              <Image
                className={styles.root__user_image}
                src={userImage2}
                alt={'Cristina'}
                priority
              />
            </div>
            <div className={styles.root__our_community_user_info}>
              <strong className={styles.root__our_community_user_name}>
                Cristina
              </strong>
              <p className={styles.root__our_community_user_city}>
                <i>Medellín, Antioquia</i>
              </p>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}
