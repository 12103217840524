import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import Image from 'next/image'
import styles from './index.module.scss'
import logo1 from '@/assets/img/register-page/landing-02.png'
import logo2 from '@/assets/img/register-page/landing-03.png'
import logo3 from '@/assets/img/register-page/landing-04.png'
import logo4 from '@/assets/img/register-page/landing-05.png'
import logo5 from '@/assets/img/register-page/landing-06.png'
import logo6 from '@/assets/img/register-page/landing-07.png'
import logo7 from '@/assets/img/register-page/landing-08.png'
import logo8 from '@/assets/img/register-page/landing-09.png'
import logo9 from '@/assets/img/register-page/landing-10.png'

export default function PreregisterBrandsSlider() {
  return (
    <Swiper
      className={styles.root}
      role={'group'}
      modules={[Autoplay, Navigation]}
      navigation
      loop={true}
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      }}
      slidesPerView={'auto'}
      breakpoints={{
        '1024': { slidesPerView: 5 },
        '300': { slidesPerView: 3 }
      }}
      onResize={(swiper) => swiper.update()}
      centerInsufficientSlides>
      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo1}
            alt={'Chevignon'}
            priority
          />
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo2}
            alt={'Americanino'}
            priority
          />
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo3}
            alt={'Esprit'}
            priority
          />
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo4}
            alt={'Max factor'}
            priority
          />
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo5}
            alt={'SAMY'}
            priority
          />
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo6}
            alt={'Oakley'}
            priority
          />
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo7}
            alt={'Lili Pink'}
            priority
          />
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo8}
            alt={'Diesel'}
            priority
          />
        </div>
      </SwiperSlide>

      <SwiperSlide className={styles.root__slide}>
        <div className={styles.root__slide_img_container}>
          <Image
            className={styles.root__image__brand_register}
            src={logo9}
            alt={'Mario Hernandez'}
            priority
          />
        </div>
      </SwiperSlide>
    </Swiper>
  )
}
