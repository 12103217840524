import {
  // IKliikerReferalsFormFields,
  TCommission
} from '@/interfaces'
import {
  kliikerDashboardComissionsCardsService,
  kliikerDashboardCommissionsGraphService,
  kliikerDashboardCommissionsTableService
  // kliikerDashboardGoalRewardsService,
  // kliikerReferalsFormService
} from '@/services'
// import { IMessageToast } from '@atoms/message-toasts'
import {
  ComissionsGraph,
  DashboardTable,
  DashboardComissionCards,
  IDashboardComissionCardsData,
  KliikerLinkCard,
  KliikerCommunity,
  // KliikerReferalsForm,
  KliikerGoals
} from '@molecules/index'
import { useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
// import { SubmitHandler } from 'react-hook-form'
import styles from './index.module.scss'
// import CardRewards from '@molecules/card-rewards'
// import { IReward } from '@/interfaces/rewards/rewards'

export default function DashboardDataSection() {
  const [cardsData, setCardsData] = useState<
    IDashboardComissionCardsData | undefined
  >()
  const [graphData, setGraphData] = useState<
    { labels: string[]; data: number[] } | undefined
  >()
  const [tableData, setTableData] = useState<TCommission[]>([])
  // const [messages, setMessages] = useState<IMessageToast[]>([])
  const { data } = useSession()
  const idToken = data?.idToken
  // const [goalRewards, setGoalRewards] = useState<IReward[]>([])

  // const handleFormSubmit: SubmitHandler<IKliikerReferalsFormFields> = async (
  //   data
  // ) => {
  //   try {
  //     if (idToken === undefined)
  //       throw new Error('¡Algo salio mal 😰!', { cause: 'No-Id-Token' })
  //     await kliikerReferalsFormService(idToken, data)
  //   } catch (ex) {
  //     setMessages((current) => [
  //       ...current,
  //       { type: 'error', message: (ex as Error).message }
  //     ])
  //     throw ex
  //   }
  // }

  useEffect(() => {
    async function fetchCards() {
      if (idToken === undefined) return
      try {
        const response = await kliikerDashboardComissionsCardsService(idToken)
        setCardsData({
          current: parseFloat(response.totalAmountByThisMonth),
          total: parseFloat(response.totalAmount)
        })
      } catch (ex) {}
    }
    fetchCards()
  }, [idToken])

  useEffect(() => {
    async function fetchGraph() {
      if (idToken === undefined) return
      try {
        const response = await kliikerDashboardCommissionsGraphService(idToken)
        setGraphData({
          labels: Object.keys(response).reverse(),
          data: Object.values(response)
            .reverse()
            .map((value) => parseFloat(value))
        })
      } catch (ex) {}
    }
    fetchGraph()
  }, [idToken])

  useEffect(() => {
    async function fetchTable() {
      if (idToken === undefined) return
      try {
        const response = await kliikerDashboardCommissionsTableService(idToken)
        setTableData(response.commissionsTable.data)
      } catch (ex) {}
    }
    fetchTable()
  }, [idToken])

  // useEffect(() => {
  //   async function fetchRewards() {
  //     if (idToken === undefined) return
  //     try {
  //       const response = await kliikerDashboardGoalRewardsService(idToken, 3)
  //       setGoalRewards(response)
  //     } catch (ex) { }
  //   }
  //   fetchRewards()
  // }, [idToken])

  return (
    <section
      role={'group'}
      aria-label={'Información de comisiones en el dashboard'}
      className={styles.root}>
      <div className={styles['root__level-1']}>
        <div className={styles['root__level-2']}>
          <KliikerLinkCard kliikerId={data?.user.uniqueAlias ?? ''} />
          <ComissionsGraph comissions={graphData} />
          <DashboardTable commissions={tableData} />
        </div>
        <div className={styles['root__level-2']}>
          <DashboardComissionCards comissions={cardsData} />
          {/* <CardRewards rewards={goalRewards} /> */}
          {/* <KliikerReferalsForm
            messages={messages}
            onMessageDismiss={(index) =>
              setMessages((currentMessages) =>
                currentMessages.filter(
                  (_, messageIndex) => index !== messageIndex
                )
              )
            }
            onSubmit={handleFormSubmit}
          /> */}
          <KliikerGoals />
          <KliikerCommunity />
        </div>
      </div>
    </section>
  )
}
