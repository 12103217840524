import styles from './index.module.scss'
import Image from 'next/image'
import cardIcon1 from '@/assets/img/register-page/financial-profit-1.png'
import cardIcon2 from '@/assets/img/register-page/financial-profit-2.png'
import cardIcon3 from '@/assets/img/register-page/financial-profit-3.png'
import cardIcon4 from '@/assets/img/register-page/financial-profit-4.png'

export default function PreregisterBenefitCards() {
  return (
    <div className={styles.root__cards_container}>
      <div className={styles.root__card}>
        <div className={styles.root__card_icon}>
          <div className={styles.root__card_icon_wrapper}>
            <Image
              className={styles.root__image_card_icon}
              src={cardIcon1}
              alt={'Genera ingresos desde casa'}
              priority
            />
          </div>
        </div>
        <div className={styles.root__card_info}>
          <h3 className={styles.root__card_heading}>
            Genera ingresos{' '}
            <br className={styles.root__form_breakline_mobile} />
            desde casa
          </h3>
          <p className={styles.root__card_text}>
            Gana comisiones semanales compartiendo tu tienda virtual desde casa
            con Kliiker.
          </p>
        </div>
      </div>

      <div className={styles.root__card}>
        <div className={styles.root__card_icon}>
          <div className={styles.root__card_icon_wrapper}>
            <Image
              className={styles.root__image_card_icon}
              src={cardIcon2}
              alt={'Sin Inversión Inicial'}
              priority
            />
          </div>
        </div>
        <div className={styles.root__card_info}>
          <h3 className={styles.root__card_heading}>Sin Inversión Inicial</h3>
          <p className={styles.root__card_text}>
            Kliiker es gratis, sin costos de inscripción ni necesidad de comprar
            inventario. Solo regístrate y comparte tu tienda para empezar a
            ganar sin riesgos.
          </p>
        </div>
      </div>

      <div className={styles.root__card}>
        <div className={styles.root__card_icon}>
          <div className={styles.root__card_icon_wrapper}>
            <Image
              className={styles.root__image_card_icon}
              src={cardIcon3}
              alt={'Despreocúpate de cobros y envíos.'}
              priority
            />
          </div>
        </div>
        <div className={styles.root__card_info}>
          <h3 className={styles.root__card_heading}>
            Despreocúpate de cobros{' '}
            <br className={styles.root__form_breakline_mobile} />y envíos.
          </h3>
          <p className={styles.root__card_text}>
            Kliiker maneja los cobros y envíos, permitiéndote enfocarte solo en
            promocionar tus productos.
          </p>
        </div>
      </div>

      <div className={styles.root__card}>
        <div className={styles.root__card_icon}>
          <div className={styles.root__card_icon_wrapper}>
            <Image
              className={styles.root__image_card_icon}
              src={cardIcon4}
              alt={'Acceso a las mejores marcas'}
              priority
            />
          </div>
        </div>
        <div className={styles.root__card_info}>
          <h3 className={styles.root__card_heading}>
            Acceso a las mejores{' '}
            <br className={styles.root__form_breakline_mobile} />
            marcas
          </h3>
          <p className={styles.root__card_text}>
            Kliiker te ofrece nuevas marcas cada mes para que promociones
            productos de calidad y ganes comisiones.
          </p>
        </div>
      </div>
    </div>
  )
}
