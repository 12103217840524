import { useEffect, useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { BrandLink, SVGComponent } from '@atoms/index'
import styles from './index.module.scss'
import { navigationLinks } from './index.data'
import Link from 'next/link'
import { signOut, useSession } from 'next-auth/react'

export default function Navbar() {
  const [isScrolling, setIsScrolling] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const { status } = useSession()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    setIsScrolling(window.scrollY > 0)
  }

  const buildNavClassName = () => {
    let className = styles.root
    className += isScrolling ? ` ${styles['root--scrolling']}` : ''
    className += isMenuVisible ? ` ${styles['root--open']}` : ''
    return className
  }

  const handleToggleClick = () => {
    setIsMenuVisible((oldVisibleState) => !oldVisibleState)
  }

  const handleNavItemsActions = async (action: string) => {
    if (action === 'log-out') {
      await signOut({ redirect: false })
    }
  }

  return (
    <nav className={buildNavClassName()}>
      <div className={styles.root__navbrand_container}>
        <BrandLink
          className={styles.root__navbrand}
          href={status === 'authenticated' ? '/portal' : '/'}
        />
        <Link
          className={styles.root__navlinkShop}
          href="https://www.tienda.kliiker.com/"
          title="Link tienda Kliiker"
          target="_blank">
          Ir a la tienda
        </Link>
      </div>
      <button
        type="button"
        className={styles['root__toggle-button']}
        title={'Botón de apertura/cierre de menú móvil'}
        onClick={handleToggleClick}>
        <FeatherIcon icon={isMenuVisible ? 'x' : 'menu'} />
      </button>
      <div className={styles['root__menu-wrapper']}>
        <menu className={styles.root__navitems}>
          {navigationLinks.map((navItem, key) => {
            const props =
              navItem.auth !== undefined && status === 'authenticated'
                ? navItem.auth
                : navItem.noAuth
            return (
              <li key={key}>
                {props.type === 'link' ? (
                  <Link
                    className={styles[`root__nav-${props.style}`]}
                    href={props.href}
                    title={props.title}>
                    {props.text}
                  </Link>
                ) : (
                  <button
                    className={styles[`root__nav-${props.style}`]}
                    onClick={() => {
                      handleNavItemsActions(props.action)
                    }}
                    title={props.title}>
                    {props.text}
                  </button>
                )}
              </li>
            )
          })}
        </menu>
        <SVGComponent
          svgPath="navbar/menu-decoration"
          className={styles['root__menu-decoration']}
        />
      </div>
    </nav>
  )
}
