import { SetStateAction, useEffect, useMemo, useState } from 'react'
import { WelcomeMessage } from '@atoms/index'
import styles from './index.module.scss'
import { useSession } from 'next-auth/react'
import Image, { StaticImageData } from 'next/image'
import IconExplorador from '@/assets/img/img-segmentation/icon-explorador.png'
import IconAprendiz from '@/assets/img/img-segmentation/icon-aprendiz.png'
import IconProfession from '@/assets/img/img-segmentation/icon-profesional.png'
import IconMaestro from '@/assets/img/img-segmentation/icon-maestro.png'
import IconLeyenda from '@/assets/img/img-segmentation/icon-leyenda.png'

import {
  IInfoSegmentation,
  IListLevelSegmentation,
  ISegmentationItems
} from '@/interfaces/module-segmentation/segmentation'
import LevelItems from '@molecules/level-items'
import ModalItemsLevel from '@molecules/modal-items-level'
import ProgressBar from '@molecules/progress-bar'
import FeatherIcon from 'feather-icons-react'
import SalesTable from '@molecules/salesTableKliiker'
import BenefitsTableSegmentation from '@molecules/benefits-table-segmetation'

import { capitalizeFuntion } from '@/utils/capitalize'
import { convertToCOP } from '@/utils/convertToCOP'
import { calculatePercentage } from '@/utils/calculatePercentage'
import { kliikerGetInfoService } from '@/services/segmentation'

interface IModuleCardSegmentationProps {
  moduleSegmentation?: IListLevelSegmentation[]
}

export default function KliikerSegmentationSection({
  moduleSegmentation
}: IModuleCardSegmentationProps): JSX.Element {
  const session = useSession()
  const user = session.data?.user

  const levelsOrder = [
    'Explorador',
    'Aprendiz',
    'Profesional',
    'Maestro',
    'Leyenda'
  ]

  const [dataSegmentation, setDataSegmentation] = useState<
    IInfoSegmentation | undefined
  >()

  const [iconLevelCurrent, setIconLevelCurrent] = useState(IconExplorador)
  const [iconLevelProx, setIconLevelProx] = useState(IconExplorador)

  const [selectedLevel, setSelectedLevel] = useState<ISegmentationItems | null>(
    null
  )
  const [showModalDetail, setShowModalDetail] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const sortSegmentationLevels = (
    data: ISegmentationItems[]
  ): ISegmentationItems[] => {
    const dataCopy = [...data]
    return dataCopy.sort((a, b) => {
      return (
        levelsOrder.indexOf(a?.nameLevel) - levelsOrder.indexOf(b?.nameLevel)
      )
    })
  }

  let sortedData: ISegmentationItems[] = []

  if (moduleSegmentation != null) {
    sortedData = sortSegmentationLevels(
      moduleSegmentation as ISegmentationItems[]
    )
  }

  const handleShowModal = (level: ISegmentationItems) => {
    setSelectedLevel(level)
  }

  const handleCloseModal = () => {
    setSelectedLevel(null)
  }
  const handleCloseModalDetail = () => {
    setShowModalDetail(!showModalDetail)
  }

  const selectIconLevel = (
    level: any,
    setIcon: {
      (value: SetStateAction<StaticImageData>): void
      (arg0: StaticImageData): void
    }
  ) => {
    switch (level) {
      case 'EXPLORADOR':
        setIcon(IconExplorador)
        break
      case 'APRENDIZ':
        setIcon(IconAprendiz)
        break
      case 'PROFESIONAL':
        setIcon(IconProfession)
        break
      case 'MAESTRO':
        setIcon(IconMaestro)
        break
      case 'LEYENDA':
        setIcon(IconLeyenda)
        break
    }
  }

  useEffect(() => {
    async function fetchSegmentation() {
      if (user === undefined) return
      try {
        const response: IInfoSegmentation = await kliikerGetInfoService(
          user?.email ?? ''
        )
        setDataSegmentation(response)
        setIsLoading(false)
      } catch (ex) {
        console.error('Error fetching segmentation data:', ex)
      }
    }
    fetchSegmentation()
  }, [user])

  useEffect(() => {
    if (dataSegmentation !== undefined) {
      selectIconLevel(dataSegmentation.segmentation, setIconLevelCurrent)
      selectIconLevel(
        dataSegmentation.nextsegmentation.level_name,
        setIconLevelProx
      )
    }
  }, [dataSegmentation])

  const memoizedDataSegmentation = useMemo(
    () => dataSegmentation,
    [dataSegmentation]
  )

  return (
    <section
      role={'contentinfo'}
      aria-label={'Sección Club de beneficios Kliiker'}
      className={styles.root}>
      <WelcomeMessage name={user?.firstname} />

      <section className={styles.root__panel}>
        {isLoading ? (
          <div className={styles.root__panel__loading}></div>
        ) : (
          <>
            <Image
              className={styles.root__panel__icon}
              src={iconLevelCurrent}
              alt={'Imagen del nivel de Kliiker'}
              priority
            />
            <h4 className={styles.root__panel__level__text}>
              {memoizedDataSegmentation?.segmentation != null
                ? capitalizeFuntion(memoizedDataSegmentation.segmentation)
                : ''}
            </h4>
            <p className={styles.root__panel__level__text_info}>
              Al registrarte e ingresar al sistema, automáticamente te
              conviertes en un <b>Explorador</b>. Sin embargo, para ascender al
              siguiente nivel, debes realizar ventas en cada uno de los
              siguientes <b>6</b> meses de manera consecutiva. Para tu próximo
              nivel (
              <b>
                {capitalizeFuntion(
                  memoizedDataSegmentation?.nextsegmentation?.level_name ??
                    'Explorador'
                )}
              </b>
              ), debes acumular ventas en cada uno de los siguientes <b>6</b>{' '}
              meses de manera consecutiva por
            </p>
            <h5 className={styles.root__panel__level__text_sales}>
              {convertToCOP(
                memoizedDataSegmentation?.nextsegmentation?.initial_amount ?? 0
              )}{' '}
              Ventas
            </h5>
            <span className={styles.root__panel__level__text_info}>
              Así avanza tu camino hacia el{' '}
              <b>
                nivel de{' '}
                {capitalizeFuntion(
                  memoizedDataSegmentation?.nextsegmentation?.level_name ??
                    'Explorador'
                )}
              </b>
              . ¡Tú puedes lograrlo!
            </span>

            <div className={styles.root__panel__detail__container}>
              <div className={styles.root__panel__detail__container_sales}>
                <Image
                  className={styles.root__panel__detail__container__icon}
                  src={iconLevelCurrent}
                  alt={'Imagen del próximo nivel de Kliiker'}
                  priority
                />
                <div
                  className={
                    styles.root__panel__detail__container_sales_content
                  }>
                  {/* <span className={styles.root__panel__detail__container_sales_content__text}>Actual</span> */}
                  <span
                    className={
                      styles.root__panel__detail__container_sales_content__text
                    }>
                    {convertToCOP(memoizedDataSegmentation?.totalcollect ?? 0)}
                  </span>
                </div>
              </div>
              <ProgressBar
                progress={
                  memoizedDataSegmentation?.totalcollect != null
                    ? calculatePercentage(
                        memoizedDataSegmentation.totalcollect,
                        memoizedDataSegmentation.nextsegmentation
                          ?.initial_amount ?? 0
                      )
                    : 0
                }
              />
              <div className={styles.root__panel__detail__container_sales}>
                <Image
                  className={styles.root__panel__detail__container__icon}
                  src={iconLevelProx}
                  alt={'Imagen del próximo nivel de Kliiker'}
                  priority
                />
                <div
                  className={
                    styles.root__panel__detail__container_sales_content
                  }>
                  {/* <span className={styles.root__panel__detail__container_sales_content__text}>Próximo</span> */}
                  <span
                    className={
                      styles.root__panel__detail__container_sales_content__text
                    }>
                    {convertToCOP(
                      memoizedDataSegmentation?.nextsegmentation
                        ?.initial_amount ?? 0
                    )}
                  </span>
                </div>
              </div>
            </div>
            <button
              className={styles.root__panel__detail__btn}
              onClick={handleCloseModalDetail}>
              {!showModalDetail ? 'Ver detalle' : 'Ocultar'}
              <FeatherIcon
                icon={!showModalDetail ? 'chevron-down' : 'chevron-up'}
                color="#1D1C1D"
              />
            </button>
            {showModalDetail && (
              <div className={styles.root__container__sales_table}>
                <SalesTable infoTable={memoizedDataSegmentation} />
              </div>
            )}
          </>
        )}
      </section>

      <section className={styles.root__image__banner_info_container}>
        {sortedData?.map((level: any, index: number) => (
          <LevelItems
            key={index}
            level={level} // Casting to Level type
            index={index}
            handleShowModal={(level: any) =>
              handleShowModal(level as ISegmentationItems)
            } // Casting to ISegmentationItems type
          />
        ))}

        {selectedLevel != null && (
          <ModalItemsLevel
            selectedLevel={selectedLevel}
            handleCloseModal={handleCloseModal}
          />
        )}
      </section>
      <section className={styles.root__table_benefits}>
        <BenefitsTableSegmentation />
      </section>
    </section>
  )
}
